
@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');



* {
font-family: 'Roboto', sans-serif;
font-weight: 300;

}

.break {
  min-height:40vh;
  width:100%;
  backrgounbd-color:rgba(0,0,0,0);
}


h3 {
  color: #555;
  font-weight:400;
  margin-top: 1.5em;
  font-size: 1.6em;
}

hr {
  background-color: #444;
  width: 10%;
  height: 1px;
}

ul {
  li{
    list-style: none;
  } 
}

.btn {
  font-size: 0.8em;
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0px !important;
  background-color: #fff;
  color: rgb(233, 22, 140);
  border: 1px solid rgb(233, 22, 140);
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #fff;
  color: rgb(233, 22, 140);
  border: 1px solid rgb(233, 22, 140); 
}


p {
  font-size: 1.1em;
  font-weight: 300;
  color: #555;
  text-align: center;
}


header {
  min-height:100vh;
  width:100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index:-1;
  position: fixed;
  top:0;
  left:0;
  z-index:-999;
}

#wrapper-headline {
  min-height:60vh;
  padding: 2em;
  position:absolute;
  display: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 

  hr {
    background-color:#fff;
     z-index:-1;
  }

  h1 {
    color: #fff;
    font-size: 3em;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
  }
  
  h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 2em;
    font-weight: 300;
    color: #fff;
  }
}


#nav {
  margin: 0;
  padding: 0;
  position: fixed;
  top:0;
  right: 2em;
  bottom: 0;

  .wrapper {
    position:absolute;
    display: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fa {
    display: block;
    margin-bottom: 0.7em;
    color: #fff;
  }
}


.page {
  width: 100%;
  z-index: 99000;
  background-color: #fff;
  z-index:1090;

  #logo {
    margin-top: 10vh;
  }

  .paragraph {
    font-size: 1.6em;
    font-weight: 400;
    color: rgb(85,85,85);
    padding:0;
    margin: 0.3em 0;
  }

  h3 {
    margin: 0.3em 0;
  }

  i {
    padding-right: 7px;
  }

  h4 {
    font-weight: 300;
    font-size: 1.5em;
    margin-top: 0.3em;
  }

  .wrapper {
    background-color: #fff;
    padding:20px;
    margin: 30px 0 30px 0;
  }

  #wrapper-help {
    padding: 1em;
  }
}


#button {
  background-color: #222;

  i {
    color: #fff;
    padding: 15px;
    cursor: pointer;
  }

}




#wrapper-herz {
  img {
    padding:2em;
  }
}

@media (max-width: 576px) {
  
  header {
    #wrapper-headline {

      h1 {
        font-size: 2em;
      }
    }
  }

  #wrapper-herz {
    padding:2em 2em 0 2em;
  }
}



footer {
  background-color:#444;
  color: #fff;
  padding:3em 1em;
  

  h3 {
    font-weight: 300;
    color: #fff;
  }
  img {
    margin:0;
    padding:0;
  }

  .wrapper-footer {
    margin:2em 0;

    span {
    text-decoration: none;
    }
  }
}

#impressum {
  color: #fff;
  margin-right: 1em;
  text-decoration: none;

  a {
  color: #fff;
  cursor: pointer;
  }
  }

  #satzung {
  color: #fff;
  text-decoration: none;
  margin-right: 1em;


  a {
  color: #fff;
  cursor: pointer;
  }
  }

.wrapper_button {
  margin-top: 2em;
}









